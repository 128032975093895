import { useContext, useEffect, useRef, useState } from "react";
import { json, Link, useNavigate } from "react-router-dom";
import { handleAddNewGameVersion } from "../../handlers/developers";
import { ColumnBox, PageBox, RowBox, ScrollableBox } from "../../components/boxes";
import DeveloperNavBar from "../../components/layout/developer-nav-bar";
import { Box, styled, useMediaQuery } from "@mui/material";
import { Text } from "../../components/text";
import Footer from "../../components/footer";
import { colors } from "../../styles/styles";
import Game from "../../game";
import { formatDateTimestamptzToMMDDYYYY } from "../../lib/global-functions";
import { RedButton } from "../../components/basic/inputs";
import { InfoBox } from "../../components/basic/containers";
import { PricingSection } from "../../components/game-management/pricing-section";
import { GameManagementContext, GameManagementProvider } from "../../context/game-management-context";
import { getFileNameFromURL } from "../../resources/s3.resource";
import GameInfoSection from "../../components/game-management/info-section";
import { FolderIcon } from "../../components/icons";
import GameAssetsSection from "../../components/game-management/assets-section";
import BuildFileHolder from "../../components/basic/build-file-container";
import GameSubmitSection from "../../components/game-management/submit-section";
import MobileDeveloperGame from "../../components/game-management/mobile-developer-game";
import SaveOverlay from "../../components/game-management/save-overlay";
import MediaSection from "../../components/game-management/media-section";

//TODO
// - move mobile version to it's own seperate file space
// - move the rest of the save state stuff into its own hook and inside the gameManagementContext
// - create hook for the test game and the socket connection

const VersionsBox = styled(Box)({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "flex-start",
	gap: 15,
	width: 222,
	height: "calc(100vh - 233px)",
	maxHeight: 575,
	minHeight: 300,
	position: "fixed",
	top: 44 + 15,
	left: 0,
	padding: 15,
	borderRadius: "0px 10px 10px 0px",
	borderTop: "0.5px solid var(--black-50, rgba(0, 0, 0, 0.50))",
	borderRight: "0.5px solid var(--black-50, rgba(0, 0, 0, 0.50))",
	borderBottom: "0.5px solid var(--black-50, rgba(0, 0, 0, 0.50))",
	backgroundColor: colors.whiteMain,
});

function DeveloperGameContainer() {
	return (
		<GameManagementProvider>
			<DeveloperGame />
		</GameManagementProvider>
	);
}

function DeveloperGame() {
	const navigate = useNavigate();
	const {
		info,
		assets,
		gameVersions: { intVersion, v, versions, gameId, updateVersion, getVersions },
		controller,
		submission: { saving },
		media,
	} = useContext(GameManagementContext);
	const isMobile = useMediaQuery("(max-width: 600px)");

	const [testOpen, setTestOpen] = useState(false);
	const [fullScreen, setFullScreen] = useState(false);
	const socketRef = useRef();
	const roomID = useRef();
	const [readyToExit, setReadyToExit] = useState(false);

	const onImageChange = (e, imgSize, setFile) => {
		const file = e.target.files[0];
		if (!file) return;

		const img = new Image();
		img.src = URL.createObjectURL(file);
		img.onload = function () {
			if (img.width !== img.height) {
				alert(`Image dimensions must be square (${imgSize}x${imgSize}px).`);
				// Clear the file input to allow the user to select again
				e.target.value = null;
				return;
			}
			if (img.width < imgSize || img.height < imgSize) {
				alert(`Image dimensions are too small, must be at least (${imgSize}x${imgSize}px).`);
				// Clear the file input to allow the user to select again
				e.target.value = null;
				return;
			}
			if (img.width > imgSize + imgSize || img.height > imgSize + imgSize) {
				alert(`Image dimensions are too large, must be at most (${imgSize + imgSize}x${imgSize + imgSize}px).`);
				// Clear the file input to allow the user to select again
				e.target.value = null;
				return;
			}
			setFile(file);
		};
	};

	const onCoverImageChange = (e) => {
		onImageChange(e, 350, info.setCoverImage);
	};

	const onFaviconChange = (e) => {
		onImageChange(e, 32, info.setFavicon);
	};

	useEffect(() => {
		//we create the function here so it doesn't get recreated when we try to remove it using a removeEventListener event
		const onFullScreenChange = () => {
			if (document.fullscreenElement === null) {
				setFullScreen(false);
			} else {
				setFullScreen(true);
			}
		};
		if (testOpen) {
			window.addEventListener("fullscreenchange", onFullScreenChange);
		}
		//clean up the event listener when the component dismounts and when ever test open changes, and also when ever setFullScreen changes
		return () => window.removeEventListener("fullscreenchange", onFullScreenChange);
	}, [testOpen, setFullScreen]);

	const handleClickTest = () => {
		setTestOpen(true);
		setFullScreen(true);
	};

	const onAddVersion = async () => {
		const data = await handleAddNewGameVersion(gameId);
		if (data !== null) {
			const response = await media.addNewVersionMedia(data);
			if (!response) console.error("failed to upload new version media", response);
			//get the new versions data so we don't get an error
			await getVersions();
			navigate(`/${gameId}/${data}`);
		}
	};

	useEffect(() => {
		if (fullScreen === true) {
			document.documentElement.requestFullscreen();
		} else if (fullScreen === false && document.fullscreenElement !== null) {
			document.exitFullscreen();
		}
	}, [fullScreen]);

	const exitGame = () => {
		if (socketRef.current) {
			socketRef.current.emit("exit", roomID.current);
		}
		setTestOpen(false);
		setFullScreen(false);
		setReadyToExit(false);
	};

	if (isMobile) {
		return <MobileDeveloperGame />;
	} else if (saving) {
		return <SaveOverlay />;
	} else if (testOpen) {
		if (assets.editUnityJsonURL) {
			const gameSelected = {
				title: info.editGameTitle,
				minPlayers: info.editMinPlayers,
				maxPlayers: info.editMaxPlayers,
				type: "unity5",
				orientation: "landscape",
				versionCompatible: "1.2.2",
				loaderUrl: assets.editUnityLoaderURL,
				jsonUrl: assets.editUnityJsonURL,
				controllerUrl: controller.url,
				loadingFile: v.loading_url,
			};
			return (
				<PageBox>
					<DeveloperNavBar fullScreen={fullScreen} setFullScreen={setFullScreen} onExit={() => setReadyToExit(true)} testOpen={testOpen} />
					<Game
						socketRef={socketRef}
						roomID={roomID}
						game={gameSelected}
						readyToExit={readyToExit}
						afterReadyToExit={exitGame}
						style={{ width: "100%", height: "100%" }}
					/>
				</PageBox>
			);
		} else {
			const gameSelected = {
				title: info.editGameTitle,
				minPlayers: info.editMinPlayers,
				maxPlayers: info.editMaxPlayers,
				type: "unity",
				orientation: "landscape",
				versionCompatible: "1.2.2",
				loaderUrl: assets.editUnityLoaderURL,
				dataUrl: assets.editUnityDataURL,
				frameworkUrl: assets.editUnityFrameworkURL,
				codeUrl: assets.editUnityCodeURL,
				controllerUrl: controller.url,
				loadingFile: v.loading_url,
			};

			return (
				<PageBox>
					<DeveloperNavBar fullScreen={fullScreen} setFullScreen={setFullScreen} onExit={() => setReadyToExit(true)} testOpen={testOpen} />
					<Game socketRef={socketRef} roomID={roomID} game={gameSelected} readyToExit={readyToExit} afterReadyToExit={exitGame} />
				</PageBox>
			);
		}
	} else if (versions.length === 0) {
		return (
			<PageBox>
				<DeveloperNavBar />
			</PageBox>
		);
	} else if (versions.length === 1 && v.status !== "Approved") {
		return (
			<PageBox>
				<DeveloperNavBar />
				<Box style={{ height: 44 + 15 }} />
				<RowBox width={"calc(100vw - 50px)"} justify={"space-between"} style={{ maxWidth: 700 }}>
					<Text size={24} weight={700}>
						{v.title ? v.title : "My Game"}
					</Text>
					<Text color={v.status === "Waiting for approval" || v.status === "Rejected" ? colors.redMain : colors.blackMain}>{v.status}</Text>
				</RowBox>
				<Box style={{ height: 15 }} />
				<ColumnBox align={"flex-start"} gap={15} width={"calc(100vw - 50px)"} style={{ maxWidth: 700 }}>
					<InfoBox>
						<Text weight={700}>Game Info</Text>
						<GameInfoSection can_edit_title={true} onCoverImageChange={onCoverImageChange} onFaviconChange={onFaviconChange} />
					</InfoBox>
					<MediaSection key={"media-section"} />
					<InfoBox>
						<Text weight={700}>Game Assets</Text>
						<GameAssetsSection />
					</InfoBox>
					<PricingSection />
					<GameSubmitSection handleClickTest={handleClickTest} />
				</ColumnBox>
				<Box style={{ flex: 1, minHeight: 25 }} />
				<Footer />
			</PageBox>
		);
	}
	return (
		<PageBox>
			<DeveloperNavBar />
			<VersionsBox>
				<Text size={versions[0].title.length < 12 ? 32 : versions[0].title.length < 20 ? 24 : 18} weight={700} align={"center"}>
					{versions[0].title}
				</Text>

				<ScrollableBox>
					{versions.map((v, i) => {
						return (
							<Link to={`/${gameId}/${v.version}`} key={i}>
								<RowBox
									width={"calc(100% - 30px)"}
									gap={15}
									justify={"space-between"}
									style={{
										padding: "5px 15px",
										borderRadius: 10,
										backgroundColor: intVersion === v.version ? colors.grayMain : "transparent",
										cursor: "pointer",
									}}
								>
									<Text weight={700} color={colors.black50}>
										V{v.version}
									</Text>
									<RowBox gap={5}>
										{v.status === "Approved" ? (
											<Text size={12} color={colors.black50}>
												{formatDateTimestamptzToMMDDYYYY(v.last_updated)}
											</Text>
										) : null}
										<Text
											size={12}
											weight={600}
											color={v.status === "Approved" ? "#92C262" : v.status === "In progress" ? colors.blackMain : colors.redMain}
										>
											{v.status}
										</Text>
										{v.status === "Approved" ? (
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M6 12.5C9.31371 12.5 12 9.81371 12 6.5C12 3.18629 9.31371 0.5 6 0.5C2.68629 0.5 0 3.18629 0 6.5C0 9.81371 2.68629 12.5 6 12.5ZM5.11713 10.1L10.1333 4.0437L9.08724 3.16664L5.02563 8.07043L2.82947 5.86082L1.86663 6.82956L5.11713 10.1Z"
													fill="#92C262"
												/>
											</svg>
										) : null}
									</RowBox>
								</RowBox>
							</Link>
						);
					})}
					<Box style={{ flex: 1, minHeight: 15 }} />
					{versions[versions.length - 1].status === "Approved" ? (
						<RedButton width={"100%"} disableRipple={true} onClick={onAddVersion}>
							<RowBox gap={10}>
								<svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
									<path
										d="M6.5 0C5.83726 0 5.3 0.537259 5.3 1.2V4.8H1.7C1.03726 4.8 0.5 5.33726 0.5 6C0.5 6.66274 1.03726 7.2 1.7 7.2H5.3V10.8C5.3 11.4627 5.83726 12 6.5 12C7.16274 12 7.7 11.4627 7.7 10.8V7.2H11.3C11.9627 7.2 12.5 6.66274 12.5 6C12.5 5.33726 11.9627 4.8 11.3 4.8H7.7V1.2C7.7 0.537258 7.16274 0 6.5 0Z"
										fill="white"
									/>
								</svg>
								<Text size={14} weight={700} color={colors.whiteMain}>
									New Version
								</Text>
							</RowBox>
						</RedButton>
					) : null}
				</ScrollableBox>
			</VersionsBox>
			<RowBox width={"100vw"} justify={"flex-start"}>
				<Box width={277} style={{ minWidth: 277 }} />
				<ColumnBox style={{ flex: 1, minWidth: 350, maxWidth: 1000 }} gap={15} align={"flex-start"}>
					<Box style={{ height: 44 }} />
					<Text size={24} weight={700}>
						Version {intVersion}
					</Text>
					<InfoBox>
						<Text weight={700}>Game Info</Text>
						{v.status === "Approved" ? (
							<>
								<Text size={12}>
									<b>Player amount:</b> {v.min_players}-{v.max_players}
								</Text>
								<Text size={12}>
									<b>Release date:</b> {formatDateTimestamptzToMMDDYYYY(v.last_updated)}
								</Text>
								<RowBox width={"100%"} gap={25}>
									<ColumnBox align={"flex-start"} gap={10} style={{ flex: 1 }}>
										<Text size={12}>
											<b>Cover image:</b>
										</Text>
										<RowBox gap={5}>
											<FolderIcon />
											<Text size={12} color={colors.black50} style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
												{getFileNameFromURL(v.thumbnail_url)}
											</Text>
										</RowBox>
									</ColumnBox>
									<ColumnBox align={"flex-start"} gap={10} style={{ flex: 1 }}>
										<Text size={12}>
											<b>Favicon:</b>
										</Text>
										<RowBox gap={5}>
											<FolderIcon />
											<Text size={12} color={colors.black50} style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
												{getFileNameFromURL(v.mini_icon_url)}
											</Text>
										</RowBox>
									</ColumnBox>
								</RowBox>
								<ColumnBox align={"flex-start"} gap={10} style={{ flex: 1 }}>
									<Text size={12}>
										<b>Description:</b>
									</Text>
									<Text size={12}>{v.description}</Text>
								</ColumnBox>
							</>
						) : (
							<GameInfoSection can_edit_title={false} onCoverImageChange={onCoverImageChange} onFaviconChange={onFaviconChange} />
						)}
					</InfoBox>
					<MediaSection key={"media-section"} />
					<InfoBox>
						<Text weight={700}>Game Assets</Text>
						{v.status === "Approved" ? (
							<ColumnBox align={"flex-start"} gap={10} width={"100%"}>
								<Text size={12}>
									<b>Unity build files:</b>
								</Text>
								<RowBox width={"100%"} gap={5} style={{ overflowX: "scroll" }} justify={"flex-start"}>
									<BuildFileHolder url={v.unity_data_url} file={null} fileExtension={".data"} />
									<BuildFileHolder url={v.unity_framework_url} file={null} fileExtension={".framework.js"} />
									<BuildFileHolder url={v.unity_loader_url} file={null} fileExtension={".loader.js"} />
									<BuildFileHolder url={v.unity_code_url} file={null} fileExtension={".wasm"} />
								</RowBox>
							</ColumnBox>
						) : (
							<GameAssetsSection />
						)}
					</InfoBox>
					<PricingSection />
					{v.status === "Approved" ? null : <GameSubmitSection handleClickTest={handleClickTest} />}
				</ColumnBox>
				<Box style={{ width: 25 }} />
			</RowBox>
			<Box style={{ flex: 1, minHeight: 25 }} />
			<Footer />
		</PageBox>
	);
}

export default DeveloperGameContainer;
