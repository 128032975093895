import { useEffect, useRef, useState } from "react";
import { useUnityContext } from "react-unity-webgl";
import { Box } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import io from "socket.io-client";
import { handleUpdateUserStats } from "../../handlers/user-to-game";
import { Text } from "../../components/text";
import { colors } from "../../styles/styles";

export default function Unity5Game({
	game,
	sendMessageToPlayer,
	sendMessageToAllPlayers,
	devicePixelRatio,
	exit,
	socketRef,
	roomID,
	readyToExit,
	afterReadyToExit,
}) {
	const users = useRef([]);
	const [roomCode, setRoomCode] = useState("");
	const [showCode, setShowCode] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [iframeElement, setIframeElement] = useState(null);

	const handleSendMessageToPlayer = (name, message) => {
		sendMessageToPlayer(users.current.find((user) => user?.name === name)?.id, message);
	};

	const handleSendMessageToAllPlayers = (message) => {
		sendMessageToAllPlayers(message);
	};

	const handleUpdateStats = (name, stats) => {
		const jsonStats = JSON.parse(stats);

		if (name.length === 13) {
			return;
		}

		handleUpdateUserStats(name, game.id, jsonStats).then((data) => {
			// console.log({data});
		});
	};

	const sendMessageToUnity = (objectName, methodName, message) => {
		if (isLoaded && iframeElement.contentWindow.gameInstance) {
			const gameInstance = iframeElement.contentWindow.gameInstance;
			gameInstance.SendMessage(objectName, methodName, message);
		} else {
			console.log("Game instance not found");
		}
	};

	const unload = () => {
		console.log("Unloading Unity...");

		if (iframeElement) {
			iframeElement.parentNode?.removeChild(iframeElement);
			setIsLoaded(false);

			console.log("Unity unloaded successfully");
		}
	};

	const handleMessageFromUnity = (event) => {
		console.log("Message from Unity", event.data);

		if (event.data.type === "ShowCode") {
			handleShowCode();
		}

		if (event.data.type === "HideCode") {
			handleHideCode();
		}

		if (event.data.type === "MessageToPlayer") {
			handleSendMessageToPlayer(event.data.detail.name, event.data.detail);
		}

		if (event.data.type === "MessageToAllPlayers") {
			handleSendMessageToAllPlayers(event.data.detail);
		}

		if (event.data.type === "UpdateStats") {
			handleUpdateStats(event.data.detail.name, event.data.detail.stats);
		}

		if (event.data.type === "Exit") {
			handleExit();
		}

		if (event.data.type === "MadderManagerReady") {
			console.log("MadderManagerReady");
			if (users.current.length !== 0) {
				console.log("registering controller");
				sendMessageToUnity("MadderManager", "RegisterMadderController", JSON.stringify({ name: users[0].name, stats: users[0].stats }));
			}
		}
	};

	const handleShowCode = () => {
		console.log("ShowCode");
		setShowCode(true);
	};

	const handleHideCode = () => {
		console.log("HideCode");
		setShowCode(false);
	};

	useEffect(() => {
		if (readyToExit) {
			users.current = [];
			unload();
			afterReadyToExit();
		}
	}, [readyToExit]);

	const handleExit = () => {
		users.current = [];
		exit();
	};

	useEffect(() => {
		if (!isLoaded) return;

		window.addEventListener("message", handleMessageFromUnity);

		return () => {
			window.removeEventListener("message", handleMessageFromUnity);
		};
	}, [isLoaded]);

	useEffect(() => {
		if (!isLoaded) return;
		socketRef.current = io.connect("https://socket.madder.app");
		socketRef.current.emit("create room", game);

		socketRef.current.on("room created", (roomId) => {
			setRoomCode(roomId);
			roomID.current = roomId;
		});

		socketRef.current.on("user joined", (user) => {
			if (!user) return;
			if (users.current.length === 0) {
				const message = { name: "host" };
				socketRef.current.emit("message to player", { id: user.id, message: JSON.stringify(message) });
			} else if (users.current.length > game.minPlayers - 2) {
				const message = { name: "enough players" };
				socketRef.current.emit("message to all players", { roomId: roomID.current, message: JSON.stringify(message) });
			}
			users.current = [...users.current, user];
			console.log("PlayerJoined", { name: user?.name, stats: user.stats });
			sendMessageToUnity("MadderManager", "RegisterMadderController", JSON.stringify({ name: user?.name, stats: user.stats }));
			socketRef.current.emit("message to player", { id: user.id, message: JSON.stringify({ name: "name", player: user?.name }) });
		});

		socketRef.current.on("user updated", (user) => {
			if (!user) return;
			users.current.forEach((u, index) => {
				if (!u) return;
				if (u.name === user?.name) {
					users.current[index] = user;
					if (index === 0) {
						socketRef.current.emit("message to player", { id: user.id, message: JSON.stringify({ name: "host" }) });
					}
					if (users.current.length > game.minPlayers - 2) {
						socketRef.current.emit("message to all players", {
							roomId: roomID.current,
							message: JSON.stringify({ name: "enough players" }),
						});
					}
				}
			});
			socketRef.current.emit("message to player", { id: user.id, message: JSON.stringify({ name: "name", player: user?.name }) });
		});

		socketRef.current.on("individual exit", (id) => {
			const user = users.current.find((user) => user.id === id);
			if (!user) return;
			sendMessageToUnity("MadderManager", "UnregisterMadderController", user?.name);
			users.current = users.current.filter((user) => user.id !== id);
		});

		socketRef.current.on("message from player", ({ id, message }) => {
			const user = users.current.find((user) => user.id === id);
			if (!user) return;
			if (message.name === "controller-state") {
				//convert message.state to json
				const messageState = JSON.parse(message.state);
				if (messageState.name === "") {
					messageState.name = user.name;
					message.state = JSON.stringify(messageState);
				}
				//double-bind plus and square
				const messageStateSquare = JSON.parse(message.state);
				messageStateSquare.square = messageState.plus;
				message.state = JSON.stringify(messageStateSquare);
				sendMessageToUnity("MadderManager", "UpdateMadderControllerState", message.state);
			} else {
				const messageToSend = { player: user?.name, message: message };
			}
		});

		const handleBeforeUnload = () => {
			socketRef.current.emit("exit", roomID.current);
		};

		window.addEventListener("beforeunload", handleBeforeUnload);
	}, [isLoaded]);

	useEffect(() => {
		if (isLoaded) return;

		//if the iframe already exists, return
		if (document.getElementById("unity-iframe")) return;

		const iframe = document.createElement("iframe");
		iframe.id = "unity-iframe";
		iframe.style.width = "100%";
		iframe.style.height = "100%";
		iframe.style.border = "none";

		iframe.onload = () => {
			const doc = iframe.contentDocument || iframe.contentWindow?.document;

			doc?.open();
			doc?.write(`
              <!DOCTYPE html>
              <html lang="en">
              <head>
                  <meta charset="UTF-8">
                  <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  <title>Unity WebGL Iframe</title>
                  <script src="${game.loaderUrl}"></script> 
                  <style>
                    html, body {
                        margin: 0;
                        padding: 0;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: stretch;
                    }
                    #unityContainer {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center
                    }
                    .webgl-content {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center
                    }
                  </style>
              </head>
              <body>
                  <div id="unityContainer" style="width: 100%; height: 100%;"></div>
                  <div className="webgl-content">
                  <script>
                      window.gameInstance = UnityLoader.instantiate("unityContainer", "${game.jsonUrl}");
    
                  </script>
                  <script>
                    // Listen for custom events dispatched from the Unity .jslib file
                    window.addEventListener("MessageToPlayer", function(event) {
                        window.parent.postMessage({ type: "MessageToPlayer", detail: event.detail }, "*");
                    });
    
                    window.addEventListener("VibrateAllPlayerControllers", function() {
                        window.parent.postMessage({ type: "MessageToAllPlayers" }, "*");
                    });
    
                    window.addEventListener("UpdateMadderPlayerStats", function(event) {
                        window.parent.postMessage({ type: "UpdateStats", detail: event.detail }, "*");
                    });
    
                    window.addEventListener("ShowCode", function() {
                        console.log("ShowCode");
                        window.parent.postMessage({ type: "ShowCode" }, "*");
                    });
    
                    window.addEventListener("HideCode", function() {
                        console.log("HideCode");
                        window.parent.postMessage({ type: "HideCode" }, "*");
                    });
                    window.addEventListener("MadderManagerReady", function() {
                        console.log("MadderManagerReady");
                        window.parent.postMessage({ type: "MadderManagerReady" }, "*");
                    });
                  </script>
              </body>
              </html>
            `);
			doc?.close();
		};

		document.getElementById("gameContainer")?.appendChild(iframe);
		setIframeElement(iframe);
		setIsLoaded(true);
	}, []);

	return (
		<>
			{!isLoaded ? (
				<Box
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						height: "100vh",
						position: "absolute",
						top: 0,
					}}
				>
					{game.loadingFile ? (
						game.loadingFile.endsWith(".json") ? (
							<Player src={game.loadingFile} loop autoplay />
						) : (
							<img src={game.loadingFile} alt="loading" />
						)
					) : null}
				</Box>
			) : null}
			<div id="gameContainer" className="webgl-content" style={{ width: "100%", height: "100%", position: "absolute" }}></div>
			<Box
				style={{
					position: "absolute",
					top: 74,
					right: 0,
					zIndex: 4,
					transform: showCode ? "translateX(0)" : "translateX(calc(100% + 21px))",
					transition: "transform 2s ease",
					width: 168,
					height: 100,
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Box
					style={{
						position: "absolute",
						top: 0,
						right: 0,
						zIndex: -1,
					}}
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="189" height="100" viewBox="0 0 189 100" fill="none">
						<path d="M189 0L21.3499 2.4611e-05L1.85475e-06 50.9825L21.3499 100L189 100V0Z" fill="#BB3D3D" />
					</svg>
				</Box>
				<Text size={18} color={colors.whiteMain} style={{ marginBottom: -10 }}>
					Room code:
				</Text>
				<Text size={54} color={colors.whiteMain} weight={700}>
					{roomCode}
				</Text>
			</Box>
		</>
	);
}
